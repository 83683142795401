import React from "react";
import qr from "../../assets/btc_qr.jpeg";
import bitcoin from "../../assets/bitcoin.png";
import ethereum from "../../assets/ethereum.png";
import usdt from "../../assets/usdt.png";
import axios from "axios";
import ethereumqr from "../../assets/etheriurmQr.jpg";
import ttr1 from "../../assets/ttr1.jpg";
import ttr2 from "../../assets/ttr2.jpg";

interface Crypto {
  name: string;
  symbol: string;
  image: string;
  qrCode: string;
  address: string;
}

const cryptoData: Crypto[] = [
  {
    name: "Bitcoin",
    symbol: "BTC",
    image: bitcoin,
    qrCode: qr,
    address: "bc1q9lq87yskehtycvs4s8s3k2y5nuu662dm8gywpq",
  },
  {
    name: "Ethereum",
    symbol: "ETH",
    image: ethereum,
    qrCode: ethereumqr,
    address: "Oxe94c1876ccbA617373C86c4167f34E45A043b4B9",
  },
  {
    name: "Tether",
    symbol: "USDT",
    image: usdt,
    qrCode: ttr1,
    address: "TC9bnes97az2ZRYRhJeiE2DyYdXaAjkaqJ",
  },
  {
    name: "Tether",
    symbol: "USDT",
    image: usdt,
    qrCode: ttr2,
    address: "Oxe94c1876ccbA617373C86c4167f34E45A043b4B9",
  },
];

const CryptoCard: React.FC<{ crypto: Crypto }> = ({ crypto }) => {
  return (
    <div className="w-[80%] lg:w-[30%] relative lg:h-[80%] p-2.5 py-5 my-2 sm:mx-0 sm:my-5 flex flex-col items-center rounded-2xl justify-evenly border-2 border-slate-200">
      <img src={crypto.image} alt={crypto.name} width={64} />
      <p>
        {crypto.name} <span>{crypto.symbol}</span>
      </p>
      <img src={crypto.qrCode} alt="" width={120} />
      <div className="w-[100%] relative my-2.5">
        <p className="w-full text-wrap break-words text-center">
          {crypto.address}
        </p>
      </div>
    </div>
  );
};

interface CryptoFormModelProps {
  closeFormCallback: () => void;
  refreshTableCallback?: () => void;
}

const CryptoFormModel: React.FC<CryptoFormModelProps> = ({
  closeFormCallback,
  refreshTableCallback,
}) => {
  const AxiosPaidTransaction = async () => {
    if (!refreshTableCallback) {
      closeFormCallback();
      return;
    }
    try {
      console.log(sessionStorage.getItem("transID"));
      const response = await axios.post(`/api/v1/transaction/paid`, {
        TransactionID: sessionStorage.getItem("transID"),
      });
      console.log(response);
      closeFormCallback();
      refreshTableCallback && refreshTableCallback();
    } catch (error: any) {
      console.log(error.response);
    }
  };

  return (
    <div className="relative w-[80%] lg:w-[90%] my-14 flex flex-col items-center shadow-lg border-2 bg-white border-slate-200">
      <div className="bg-white w-full h-full py-8 px-1 flex flex-col md:flex-row md:flex-wrap lg:flex-row items-center justify-evenly ">
        {cryptoData.map((crypto, index) => (
          <CryptoCard key={index} crypto={crypto} />
        ))}
      </div>
      <button
        onClick={AxiosPaidTransaction}
        className=" mb-5 left-[48%] bg-blue-500 px-8 py-1 text-white rounded"
      >
        Pay Now
      </button>
    </div>
  );
};

export default CryptoFormModel;
