import { Box, Card, Grid } from "@mui/material";
import React from "react";
import BitCoinWidget, {
  BitUsdtCoinWidget,
  EURUSDCoinWidget,
  EthCoinWidget,
  GBPUSDCoinWidget,
  GoldCoinWidget,
} from "../components/TradeCenter/Graphs";

const TradeCenter = () => {
  return (
    <div className="w-[100%] h-[100%] relative px-5 py-5 flex flex-col items-center overflow-y-scroll scrollbar-hide transition-w duration-300 ease-in-out">
      <Box
        sx={{
          maxHeight: "calc(100vh - 50px)",
          p: 4,
          pt: 2,
          pb: 10,
          overflow: "scroll",
        }}
        className="scrollbar-hide"
      >
        <Grid container gap={2}>
          <Card>
            <BitCoinWidget />
          </Card>
          <Card>
            <EthCoinWidget />
          </Card>
          <Card>
            <BitUsdtCoinWidget />
          </Card>
          <Card>
            <EURUSDCoinWidget />
          </Card>
          <Card>
            <GBPUSDCoinWidget />
          </Card>
          <Card>
            <GoldCoinWidget />
          </Card>
        </Grid>
      </Box>
    </div>
  );
};

export default TradeCenter;
