import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCreditCard } from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
interface DepositHistoryTableRowProps {
  id: string;
  status: "pending" | "Inprogress" | "complete";
  depositID: string;
  type: string;
  amount: number;
  transactionDate: string;
  callbackToggleCardForm: () => void;
  callbackToggleCryptoForm: () => void;
  openModal: () => void;
}
const DepositHistoryTableRow: React.FC<DepositHistoryTableRowProps> = ({
  id,
  status,
  depositID,
  type,
  amount,
  transactionDate,
  callbackToggleCardForm,
  callbackToggleCryptoForm,
  openModal,
}) => {
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-50 dark:hover:bg-gray-600">
      <th
        scope="row"
        id="name"
        className="px-4 py-4 text-gray-900 whitespace-nowrap dark:text-white"
      >
        <p className="text-sm  capitalize font-medium bg-yellow-300 text-center w-max px-3 text-black py-0.5 rounded">
          {status}
        </p>
      </th>
      <th
        scope="row"
        id="name"
        className="px-4 py-4 font-medium text-salte-600 whitespace-nowrap dark:text-white"
      >
        <p className="text-sm font-medium">{depositID}</p>
      </th>
      <td className="px-4 py-4 capitalize">
        <p className="bg-slate-100 w-max px-3 py-[2px] rounded-sm text-sm font-semibold">
          {type}
        </p>
      </td>
      <td className="px-4 py-4 capitalize">${amount}</td>
      <td className="px-4 py-4 capitalize">{transactionDate}</td>
      <td className="px-0 py-4 capitalize">
        <button
          onClick={() => {
            sessionStorage.setItem("transID", id);
            callbackToggleCryptoForm();
          }}
          className="bg-[#F9B146] text-black font-semibold capitalize px-5 rounded-md py-1"
        >
          Pay with crypto
        </button>
      </td>
      <td className="px-2 py-4 capitalize cursor-pointer">
        <div
          onClick={() => {
            sessionStorage.setItem("transAmount", String(amount));
            callbackToggleCardForm();
          }}
          className="bg-[#F9B146] flex flex-row p-2.5 items-center justify-center rounded-[50%] w-max"
        >
          <FaRegCreditCard size={22} color="black" />
        </div>
      </td>
      <td className="px-2 py-4 capitalize cursor-pointer">
        <div
          onClick={() => {
            sessionStorage.setItem("transAmount", String(amount));
            openModal();
          }}
          className="bg-[#F9B146] flex flex-row p-2.5 items-center justify-center rounded-[50%] w-max"
        >
          <CiBank size={22} color="black" />
        </div>
      </td>
    </tr>
  );
};
interface TransactionDataInterfacce {
  _id: string;
  userID: string;
  status: "pending" | "Inprogress" | "complete";
  depositID: string;
  type: string;
  amount: number;
  transactionDate: string;
  paymentMethod: string;
  transactionConfirmed: boolean;
}
interface DepositHistoryTableProps {
  refreshtable: boolean;
  toggleCardpayment: () => void;
  toggleCryptoPayments: () => void;
}
const DepositHistoryTable: React.FC<DepositHistoryTableProps> = ({
  refreshtable,
  toggleCardpayment,
  toggleCryptoPayments,
}) => {
  const [transactionData, setTransactionData] = useState<
    TransactionDataInterfacce[]
  >([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/transaction/${sessionStorage.getItem("userID")}`
      );
      setTransactionData(response.data.data);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [refreshtable]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "821px" }, // Responsive width
            bgcolor: "#ffffff",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{
              textAlign: "center",
              pb: 2,
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            Bank Details
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ fontSize: "18px", pb: 1 }}
              variant="h6"
              component="h6"
            >
              Account Details:
            </Typography>
            <Box
              sx={{
                fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Responsive flex direction
                gap: 2,
                color: "#444444",
                fontFamily: "Poppins",
                "& .col": {
                  flexBasis: { xs: "100%", sm: "50%" }, // Responsive flex basis
                },
              }}
            >
              <div className="row" style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: { sm: "230px" },
                      flexShrink: { sm: 0 },
                      mb: { xs: 1, sm: 0 },
                    }}
                  >
                    Account Number:
                  </Box>
                  <Box sx={{ flex: 1 }}>MT53PAPY36836000002676370071677</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: { sm: "230px" },
                      flexShrink: { sm: 0 },
                      mb: { xs: 1, sm: 0 },
                    }}
                  >
                    Beneficiary Name:
                  </Box>
                  <Box sx={{ flex: 1 }}>Karlis Drubazs</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: { sm: "230px" },
                      flexShrink: { sm: 0 },
                      mb: { xs: 1, sm: 0 },
                    }}
                  >
                    The Beneficiary Bank:
                  </Box>
                  <Box sx={{ flex: 1 }}>Papaya Ltd</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: { sm: "230px" },
                      flexShrink: { sm: 0 },
                      mb: { xs: 1, sm: 0 },
                    }}
                  >
                    The Beneficiary Bank Bic:
                  </Box>
                  <Box sx={{ flex: 1 }}>PAPYMTMTXXX</Box>
                </Box>
              </div>
            </Box>
          </Box>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                background: "#DCDCDC",
                color: "#000",
                ":hover": { backgroundColor: "#000", color: "#ffffff" },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <div className="relative w-full overflow-x-auto shadow sm:rounded-lg px-4 py-4 scrollbar-hide">
        <table className="w-full relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 capitalize">
            <tr>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3">
                Deposit ID
              </th>
              <th scope="col" className="px-4 py-3">
                type
              </th>
              <th scope="col" className="px-4 py-3">
                Amount
              </th>
              <th scope="col" className="px-4 py-3">
                date
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((item, index) => {
              return (
                <DepositHistoryTableRow
                  key={item._id}
                  id={item._id}
                  transactionDate={item.transactionDate}
                  type={item.type}
                  status={item.status}
                  depositID={item.depositID}
                  amount={item.amount}
                  callbackToggleCardForm={toggleCardpayment}
                  callbackToggleCryptoForm={toggleCryptoPayments}
                  openModal={handleOpen}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DepositHistoryTable;
