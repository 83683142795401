import React from "react";
import { IoCaretBackCircleSharp } from "react-icons/io5";

interface DepositFormProps {
  CancelFormCallback: () => void;
}

const DepositForm: React.FC<DepositFormProps> = ({ CancelFormCallback }) => {
  return (
    <div className="relative xl:w-[56%] lg:w-[66%] md:w-[80%] w-[90%] h-auto md:h-[70%] py-8 px-10 bg-white flex flex-col items-center justify-evenly shadow-lg border-2 border-slate-200">
      <div className="w-full flex md:flex-row items-center justify-between">
        <IoCaretBackCircleSharp size={24} className="text-[#C2BAFF]" />
        <div className="flex flex-col items-start md:flex-row md:items-center">
          <p className="uppercase font-semibold text-sm mr-4">Add balance</p>
          <div className="flex flex-row items-center relative my-1 md:my-0">
            <p className="font-semibold text-lg absolute mx-2">$</p>
            <input
              type="text"
              className="bg-gray-50 border ps-6 font-semibold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              placeholder=""
              value={sessionStorage.getItem("transAmount") || ""}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[2px] bg-gray-200"></div>
      <div className="w-full sm:px-10">
        <p className="uppercase font-semibold text-sm my-3">
          Enter Your card Details
        </p>
        <div className="flex flex-col md:flex-row items-center justify-evenly w-full my-3">
          <div className="relative w-full mx-2 my-3 md:my-0">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Name on Card
            </label>
          </div>
          <div className="relative w-full mx-2 my-3 md:my-0">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Card Number
            </label>
          </div>
        </div>
        {/* other card details */}
        <div className="flex flex-col md:flex-row items-center w-full justify-evenly">
          <div className="relative mx-2 w-full my-4 md:my-0">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Expiry month
            </label>
          </div>
          <div className="relative mx-2 w-full my-4 md:my-0">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Expiry Year
            </label>
          </div>
          <div className="relative mx-2 w-full my-4 md:my-0">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              CVV
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col-reverse sm:flex-row items-center sm:items-end justify-end my-2 ">
        <button
          onClick={CancelFormCallback}
          className="bg-[#D9D9D9] w-full md:w-max uppercase font-semibold px-5 rounded py-1 mx-3 my-3"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            CancelFormCallback();
            alert("Card Payment has been declined");
          }}
          className="bg-[#C2BAFF] w-full md:w-max uppercase font-semibold px-5 rounded py-1 mx-3 my-3"
        >
          complete payment
        </button>
      </div>
    </div>
  );
};

export default DepositForm;
