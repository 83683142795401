import React from "react";
import { FaUser } from "react-icons/fa";
import person from "../assets/person.jpg";
import { useSelector } from "react-redux";
import { storeInterface } from "../store/store";

const ProfilePage = () => {
  const user = useSelector((store: storeInterface) => store.userAuth);
  return (
    <div className={`w-100 h-full p-4 rounded-md`}>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex">
          <div className="flex-col">
            <div className="flex justify-start align-middle items-center gap-4">
              <FaUser size={44} color="#17559D" />
              <div className="text-[#17559D] font-bold">Profile</div>
            </div>
            <div className="flex p-16">
              <img
                src={person}
                alt=""
                width={200}
                className="rounded-[50%] mx-2 sm:mx-4"
                height={200}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center">
            <div className="text-[#17559D] font-bold">
              First Name: {user.userDetails.firstname}
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-[#17559D] font-bold">
              Last Name: {user.userDetails.lastname}
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-[#17559D] font-bold">
              Email: {user.userDetails.email}
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-[#17559D] font-bold">
              Phone: {user.userDetails.phonenumber}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
