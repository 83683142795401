import React, { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AxiosSignUpUser } from "../api/services";
import { countries } from "./CountriesData";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  currency: string;
  gender: string;
  country: string;
  accountType: string;
  password: string;
  confirmPassword: string;
}

interface FormErrors {
  [key: string]: string;
}

function SignUpPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    currency: "",
    gender: "",
    country: "",
    accountType: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setErrors({ ...errors, [e.target.id]: "" });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    // Perform form validation
    const newErrors: FormErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof FormData]) {
        newErrors[key as keyof FormData] = "This field is required";
      }
    });

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Form data is valid, you can submit it
    console.log("Form data submitted:", formData);
    ActionSubmitForm();
  };

  const ActionSubmitForm = async () => {
    const body = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email.toLowerCase(),
      password: formData.password,
      phonenumber: formData.phoneNumber,
      trdingCurrency: formData.currency,
      gender: formData.gender,
      country: formData.country,
      tier: formData.accountType,
    };
    const response = await AxiosSignUpUser(body);
    console.log(response)
    if (response) {
      navigate("/login", { replace: true });
    }
  };
  return (
    <div className="w-[100%] h-[100%] flex flex-col items-center justify-center bg-[#f8f8ff]">
      <div className="relative w-[100%] sm:w-[90%] max-w-md my-16 mx-4 p-6 border-2 flex flex-col items-center bg-white rounded-xl shadow-lg border-slate-200">
        <p className="w-full text-center text-lg font-bold text-slate-950 mb-5 uppercase">
          SIGN UP Crypto TRADING OPTION
        </p>
        <div className="mb-5 w-full">
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className={`bg-slate-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
              errors.firstName ? "border-red-500" : ""
            }`}
            placeholder="First Name"
            required
          />
          {errors.firstName && (
            <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className={`bg-slate-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
              errors.lastName ? "border-red-500" : ""
            }`}
            placeholder="Last Name"
            required
          />
          {errors.lastName && (
            <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <input
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className={`bg-slate-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
              errors.email ? "border-red-500" : ""
            }`}
            placeholder="Email"
            required
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <input
            type="text"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className={`bg-slate-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
              errors.phoneNumber ? "border-red-500" : ""
            }`}
            placeholder="Phonenumber"
            required
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <select
            id="currency"
            value={formData.currency}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.currency ? "border-red-500" : ""
            }`}
          >
            <option selected>Select Trading Currency</option>
            {countries.map((country) => {
              return (
                <option value={country.currency.code}>
                  {country.currency.code} {country.currency.symbol}
                </option>
              );
            })}
          </select>
          {errors.currency && (
            <p className="text-red-500 text-xs mt-1">{errors.currency}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <select
            id="gender"
            value={formData.gender}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.currency ? "border-red-500" : ""
            }`}
          >
            <option selected>Select Sex</option>
            <option value="US">Male</option>
            <option value="CA">Female</option>
          </select>
          {errors.gender && (
            <p className="text-red-500 text-xs mt-1">{errors.gender}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <select
            id="country"
            value={formData.country}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.country ? "border-red-500" : ""
            }`}
          >
            <option selected>Choose a country</option>
            {countries.map((country) => {
              return <option value={country.name}>{country.name}</option>;
            })}
          </select>
          {errors.country && (
            <p className="text-red-500 text-xs mt-1">{errors.country}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <select
            id="accountType"
            value={formData.accountType}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.accountType ? "border-red-500" : ""
            }`}
          >
            <option selected>Select Account Type</option>
            <option value="CA">standard account</option>
            <option value="FR">premium account</option>
            <option value="DE">diamond account</option>
          </select>
          {errors.accountType && (
            <p className="text-red-500 text-xs mt-1">{errors.accountType}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <input
            id="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.password ? "border-red-500" : ""
            }`}
            required
            placeholder="Password"
          />
          {errors.password && (
            <p className="text-red-500 text-xs mt-1">{errors.password}</p>
          )}
        </div>
        <div className="mb-5 w-full">
          <input
            id="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`bg-gray-50 border capitalize border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.confirmPassword ? "border-red-500" : ""
            }`}
            required
            placeholder="Confirm Password"
          />
          {errors.confirmPassword && (
            <p className="text-red-500 text-xs mt-1">
              {errors.confirmPassword}
            </p>
          )}
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="text-white bg-slate-950 hover:bg-slate-800 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full mt-5 px-5 py-2.5 text-center"
        >
          Sign Up
        </button>
        <div className="text-sm mt-6 font-medium">
          <p>
            Already have an account
            <Link to={"/login"} className="text-blue-500 ml-2">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
