import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiBank } from "react-icons/ci";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import Button from "@mui/material/Button";

interface DepositHistoryTableRowProps {
  id: string;
  status: "pending" | "Inprogress" | "complete";
  withdrawID: string;
  type: string;
  amount: number;
  transactionDate: string;
  callbackToggleCardForm: () => void;
  callbackToggleCryptoForm: () => void;
  openModal: () => void;
  setCurrentRow: any;
  item: TransactionDataInterfacce;
}
const WithdrawHistoryTableRow: React.FC<DepositHistoryTableRowProps> = ({
  id,
  status,
  withdrawID,
  type,
  amount,
  transactionDate,
  callbackToggleCardForm,
  callbackToggleCryptoForm,
  openModal,
  setCurrentRow,
  item,
}) => {
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-50 dark:hover:bg-gray-600">
      <th
        scope="row"
        id="name"
        className="px-4 py-4 text-gray-900 whitespace-nowrap dark:text-white"
      >
        <p className="text-sm  capitalize font-medium bg-yellow-300 text-center w-max px-3 text-black py-0.5 rounded">
          {status}
        </p>
      </th>
      <th
        scope="row"
        id="name"
        className="px-4 py-4 font-medium text-salte-600 whitespace-nowrap dark:text-white"
      >
        <p className="text-sm font-medium">{withdrawID}</p>
      </th>
      <td className="px-4 py-4 capitalize">
        <p className="bg-slate-100 w-max px-3 py-[2px] rounded-sm text-sm font-semibold">
          {type}
        </p>
      </td>
      <td className="px-4 py-4 capitalize">${amount}</td>
      <td className="px-4 py-4 capitalize">{transactionDate}</td>
      <td className="px-0 py-4 capitalize">
        <button
          onClick={() => {
            setCurrentRow(() => item);
            callbackToggleCryptoForm();
          }}
          className="bg-[#F9B146] text-black font-semibold capitalize px-5 rounded-md py-1"
          disabled={status[0] === "pending"}
        >
          Pay with crypto
        </button>
      </td>
      {/* <td className="px-2 py-4 capitalize cursor-pointer">
        <div
          onClick={() => {
            sessionStorage.setItem("transAmount", String(amount));
            callbackToggleCardForm();
          }}
          className="bg-[#F9B146] flex flex-row p-2.5 items-center justify-center rounded-[50%] w-max"
        >
          <FaRegCreditCard size={22} color="black" />
        </div>
      </td> */}
      <td className="px-2 py-4 capitalize cursor-pointer">
        <button
          disabled={status[0] === "pending"}
          onClick={() => {
            setCurrentRow(() => item);
            openModal();
          }}
          className="bg-[#F9B146] flex flex-row p-2.5 items-center justify-center rounded-[50%] w-max"
        >
          <CiBank size={22} color="black" />
        </button>
      </td>
    </tr>
  );
};
interface TransactionDataInterfacce {
  _id: string;
  userID: string;
  status: "pending" | "Inprogress" | "complete";
  withdrawID: string;
  type: string;
  amount: number;
  withdrawalDate: string;
  paymentMethod: string;
  transactionConfirmed: boolean;
}
interface DepositHistoryTableProps {
  refreshtable: boolean;
  toggleCardpayment: () => void;
  toggleCryptoPayments: () => void;
  setCurrentRow: any;
  currentRow?: TransactionDataInterfacce;
  setOpen: any;
  setType: any;
  setSnackText: any;
  refreshTableCallback?: () => void;
}
const WithdrawHistoryTable: React.FC<DepositHistoryTableProps> = ({
  refreshtable,
  toggleCardpayment,
  toggleCryptoPayments,
  setCurrentRow,
  currentRow,
  setOpen,
  setType,
  setSnackText,
  refreshTableCallback,
}) => {
  const [transactionData, setTransactionData] = useState<
    TransactionDataInterfacce[]
  >([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/withdrawal/getwithdrawal`);
      setTransactionData(response.data.data);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [refreshtable]);
  const [open, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [formData, setFormData] = useState({
    bankName: "",
    accNumber: "",
    accountName: "",
    swiftCode: "",
    routineNumber: "",
    zipCode: "",
    city: "",
  });

  const handleSubmit = async () => {
    // Check if all values are filled
    const allFieldsFilled = Object.values(formData).every((x) => x !== "");

    if (allFieldsFilled) {
      const body = {
        WithdrawalID: currentRow?.["_id"],
        paymentMethod: "Cash",
        withdrawalAddress: {
          bank: formData,
        },
      };
      try {
        const response = await axios.post("/api/v1/withdrawal/confirm", body);
        setType("success");
        setSnackText(response.data.message);
        setOpen(true);
        handleClose();
        refreshTableCallback && refreshTableCallback();
        setFormData({
          bankName: "",
          accNumber: "",
          accountName: "",
          swiftCode: "",
          routineNumber: "",
          zipCode: "",
          city: "",
        });
      } catch (error: any) {
        setOpen(true);
        setType("error");
        setSnackText("Something went wrong");
      }
    } else {
      setOpen(true);
      setType("error");
      setSnackText("Please Enter All Fields");
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "821px" }, // Responsive width
            height: "450px",
            bgcolor: "#ffffff",
            borderRadius: 4,
            boxShadow: 24,
            overflow: "scroll",
            // Hide scrollbar - Webkit browsers
            "&::-webkit-scrollbar": {
              display: "none",
            },

            // Hide scrollbar - Firefox, IE, and Edge
            msOverflowStyle: "none" /* IE and Edge */,
            scrollbarWidth: "none" /* Firefox */,
          }}
        >
          <div className="w-full flex md:flex-row items-center justify-between p-8">
            <IoCaretBackCircleSharp size={24} className="text-[#C2BAFF]" />
            <div className="flex flex-col items-start md:flex-row md:items-center">
              <p className="uppercase font-semibold text-sm mr-4">Amount</p>
              <div className="flex flex-row items-center relative my-1 md:my-0 w-[50%]">
                <p className="font-semibold text-lg absolute mx-2">$</p>
                <input
                  type="number"
                  disabled
                  className="bg-gray-50 border ps-6 font-semibold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  placeholder=""
                  // onChange={(e) => setAmount(e.target.value)}
                  value={currentRow?.amount}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[2px] bg-gray-200"></div>
          <Box sx={{ mt: 2, px: 2 }}>
            <div className="flex flex-col md:flex-row items-center justify-evenly w-full my-3">
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="text"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, bankName: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Bank Name
                </label>
              </div>
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="number"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, accNumber: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Acc Number
                </label>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-evenly w-full my-3">
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="text"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, accountName: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Account Name
                </label>
              </div>
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="number"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, swiftCode: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Swift Code
                </label>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-evenly w-full my-3">
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="number"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, routineNumber: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Routine Number
                </label>
              </div>
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="number"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, zipCode: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Zip Code
                </label>
              </div>
              <div className="relative w-full mx-2 my-3 md:my-0">
                <input
                  type="text"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
                <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  City
                </label>
              </div>
            </div>
          </Box>
          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", p: 4 }}
          >
            <Button
              variant="contained"
              sx={{
                background: "#C2BAFF",
                color: "#000",
                ":hover": { backgroundColor: "#C2BAFF", color: "#000" },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <div className="relative w-full overflow-x-auto shadow sm:rounded-lg px-4 py-4 scrollbar-hide">
        <table className="w-full relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 capitalize">
            <tr>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3">
                Withdrawal ID
              </th>
              <th scope="col" className="px-4 py-3">
                type
              </th>
              <th scope="col" className="px-4 py-3">
                Amount
              </th>
              <th scope="col" className="px-4 py-3">
                date
              </th>
              <th scope="col" className="px-4 py-3">
                Crytpo Withdrawal
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((item, index) => {
              return (
                <WithdrawHistoryTableRow
                  key={item._id}
                  id={item._id}
                  transactionDate={item.withdrawalDate}
                  type={item.paymentMethod}
                  status={item.status}
                  withdrawID={item.withdrawID}
                  amount={item.amount}
                  callbackToggleCardForm={toggleCardpayment}
                  callbackToggleCryptoForm={toggleCryptoPayments}
                  openModal={handleOpen}
                  setCurrentRow={setCurrentRow}
                  item={item}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WithdrawHistoryTable;
