import React, { useCallback, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import axios from "axios";
import HomeIndex from "./components/Home/HomeIndex";
import DepositIndex from "./components/deposit/DepositIndex";
import PricingPlans from "./pages/UpgradePlans";
import ProfilePage from "./pages/ProfilePage";
import { useDispatch, useSelector } from "react-redux";
import { storeInterface } from "./store/store";
import { FetchUserProfile } from "./api/services";
import { setUserBalance } from "./store/reducers/UserReducer";
import TradeCenter from "./pages/TradeCenter";
import WithdrawIndex from "./components/withdraw/WithdrawIndex";

// uncomment this in production
axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:8000";

axios.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("crypto-OauthToken");
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const isAuthenticated = () => {
  return sessionStorage.getItem("cryto-loggedIn") === "true";
};

function App() {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  const user = useSelector((state: storeInterface) => state.userAuth);
  const dispatch = useDispatch();
  const fetchUser = useCallback(async () => {
    const response = await FetchUserProfile();
    dispatch(setUserBalance(response.totalBalance));
  }, [dispatch]);
  useEffect(() => {
    if (!authenticated) {
      // Allow navigation to both login and sign up pages
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/register"
      ) {
        navigate("/login");
      }
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (authenticated) {
      fetchUser();
    }
  }, [user, fetchUser, authenticated]);
  return (
    <div className="w-full h-full">
      <Routes>
        {authenticated && (
          <Route path="/" element={<Dashboard />}>
            <Route index element={<HomeIndex />} />
            <Route path="/deposit" element={<DepositIndex />} />
            <Route path="/withdraw" element={<WithdrawIndex />} />
            <Route path="/upgrade" element={<PricingPlans />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/trade-center" element={<TradeCenter />} />
          </Route>
        )}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignUpPage />} />
      </Routes>
    </div>
  );
}

export default App;
