import React, { useState } from "react";
import { IoMdPerson } from "react-icons/io";
import { FaMoneyBill } from "react-icons/fa";
import DepositHistoryTable from "./DepositHistoryTable";
import DepositForm from "./DepositForm";
import axios from "axios";
import CryptoFormModel from "./CryptoFormModel";
import { useSelector } from "react-redux";
import { storeInterface } from "../../store/store";

function DepositIndex() {
  const user = useSelector((store: storeInterface) => store.userAuth);
  const [togglePaymentForm, setTooglePaymentForm] = useState(false);
  const [toggleCryptoForm, setToogleCryptoForm] = useState(false);
  const [Amount, setAmount] = useState("");
  const [refresh, setRefresh] = useState(false);
  const AxiosCreateTransaction = async () => {
    if (Amount === "") {
      return;
    }
    const body = {
      amount: parseFloat(Amount),
      transactionDate: new Date().toLocaleDateString(),
    };
    try {
      const response = await axios.post("/api/v1/transaction", body);
      console.log(response.data);
      setRefresh(!refresh);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  return (
    <div className="w-[100%] h-[100%] relative px-5 py-5 flex flex-col items-center overflow-y-scroll scrollbar-hide transition-w duration-300 ease-in-out">
      {/* investment input form */}
      <div className="w-full">
        <p className="text-lg font-semibold mb-5">Investment Capital</p>
        <div className="w-full flex flex-col items-start">
          <div className="w-[80%] sm:w-[60%] lg:w-[36%] flex flex-row items-center rounded-lg border bg-gray-200 text-gray-900 flex-1 min-w-0 text-sm pl-4 my-4">
            <IoMdPerson size={28} className="text-[#6FA7E7]" />
            <input
              type="text"
              id="website-admin"
              disabled
              className=" bg-inherit border-none outline-none focus-within:outline-none focus:outline-none focus:border-none active:outline-none active:border-none ml-2"
              placeholder={user.UserAccountID || ""}
            />
          </div>
          <div className="w-[80%] sm:w-[60%]  lg:w-[36%] flex flex-row items-center rounded-lg border text-gray-900 flex-1 min-w-0 text-sm pl-4 my-4">
            <FaMoneyBill size={28} className="text-[#6FA7E7]" />
            <input
              type="text"
              value={Amount}
              onChange={(e) => setAmount(e.target.value)}
              id="website-admin"
              className=" bg-inherit border-none outline-none focus-within:outline-none focus:outline-none focus:border-none active:outline-none active:border-none ml-2"
              placeholder="Amount"
            />
          </div>
          <button
            onClick={AxiosCreateTransaction}
            className="bg-[#5B9E72] text-white font-semibold text-lg capitalize w-[80%]  lg:w-[36%] sm:w-[60%] py-1.5 rounded-lg"
          >
            create deposit transaction
          </button>
        </div>
      </div>
      {/* Table depositor */}
      <div className="w-full">
        <p className="font-semibold text-lg my-3">My Deposit History</p>
        <DepositHistoryTable
          refreshtable={refresh}
          toggleCardpayment={() => setTooglePaymentForm(true)}
          toggleCryptoPayments={() => setToogleCryptoForm(true)}
        />
      </div>
      {togglePaymentForm && (
        <div className="absolute flex flex-col items-center justify-center z-20 backdrop-blur-sm w-full h-[95%] overflow-hidden">
          <DepositForm
            CancelFormCallback={() => {
              setTooglePaymentForm(false);
            }}
          />
        </div>
      )}
      {toggleCryptoForm && (
        <div className="absolute flex flex-col min-h-full items-center justify-center z-10 backdrop-blur-sm w-full overflow-hidden">
          <CryptoFormModel
            closeFormCallback={() => setToogleCryptoForm(false)}
            refreshTableCallback={() => setRefresh(!refresh)}
          />
        </div>
      )}
    </div>
  );
}

export default DepositIndex;
