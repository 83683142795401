import React, { useState } from "react";
import CryptoFormModel from "../components/deposit/CryptoFormModel";
interface PlanCardProps {
  price: string;
  title: string;
  features: string[];
  mostPopular: boolean;
  openForm : () => void
}

const PlanCard: React.FC<PlanCardProps> = ({
  price,
  title,
  features,
  mostPopular,
  openForm
}) => {

  return (
    <>
      <div className={`w-full sm:w-1/2 lg:w-1/4 px-4 mb-8 rounded-md`}>
        <div className="bg-custom-gray rounded-lg shadow-md relative">
          {mostPopular && (
            <div className="w-100 mb-2 ribbon rounded-md bg-custom-yell p-6 text-custom-blue px-2 py-2 text-center right-0 top-0 text-xs font-bold">
              MOST POPULAR
            </div>
          )}
          <h2
            className={`text-center text-2xl font-bold ${
              !mostPopular && "pt-10"
            } text-custom-blue mb-4`}
          >
            {price}
          </h2>
          <h3 className="text-center text-lg text-custom-blue pb-3 border-b-2 border-custom-border">
            {title}
          </h3>
          <ul className="text-center text-gray-600">
            {features.map((feature, index) => (
              <li
                className="p-3 border-b-2 border-custom-border text-xs"
                key={index}
              >
                {feature}
              </li>
            ))}
          </ul>
          <div className="flex justify-center py-4">
            <button
            onClick={openForm}
              className={`py-2 px-4 rounded ${"bg-custom-blue hover:bg-custom-hover-blue"} text-white`}
            >
              COMPLETE PAYMENT
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const UpgradePlans = () => {
  const plans = [
    {
      priceRange: "$9.99 - 19.99",
      title: "STARTUP PLAN",
      features: [
        "24x7 Support",
        "Earn 210% from a $500 Account in 7 working days",
        "Earn 280% from a $700 Account in 7 working days",
        "Earn 360% from a $1,000 Account in 7 working days",
      ],
      mostPopular: false,
    },
    {
      priceRange: "$29.99 - 49.99",
      title: "STANDARD PLAN",
      features: [
        "24x7 Support",
        "Earn 210% from a $500 Account in 7 working days",
        "Earn 280% from a $700 Account in 7 working days",
        "Earn 360% from a $1,000 Account in 7 working days",
      ],
      mostPopular: false,
    },
    {
      priceRange: "$59.99 - 89.99",
      title: "PREMIUM PLAN",
      features: [
        "24x7 Support",
        "Earn 210% from a $500 Account in 7 working days",
        "Earn 280% from a $700 Account in 7 working days",
        "Earn 360% from a $1,000 Account in 7 working days",
      ],
      mostPopular: true,
    },
    {
      priceRange: "$99.99 - 199.99",
      title: "PRO PLAN",
      features: [
        "24x7 Support",
        "Earn 210% from a $500 Account in 7 working days",
        "Earn 280% from a $700 Account in 7 working days",
        "Earn 360% from a $1,000 Account in 7 working days",
      ],
      mostPopular: false,
    },
  ];
  const [toggleCryptoForm, setToogleCryptoForm] = useState(false);

  return (
    <>
      <div className="w-[100%] h-[100%] relative px-5 py-5 flex flex-col items-center overflow-y-scroll scrollbar-hide transition-w duration-300 ease-in-out">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h1 className="font-bold text-4xl text-gray-900">Upgrade Plans</h1>
            <p className="text-gray-600 mt-4">
              Lorem ipsum is simply dummy text of the printing and typesetting
              industry. Lorem ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a gallery of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="flex flex-wrap -mx-4 justify-center">
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                price={plan.priceRange}
                title={plan.title}
                features={plan.features}
                mostPopular={plan.mostPopular}
                openForm={()=> setToogleCryptoForm(true)}
              />
            ))}
          </div>
        </div>
        {
          toggleCryptoForm && (
            <div className="absolute flex flex-col min-h-full h-max items-center justify-center z-10 backdrop-blur-sm w-full overflow-hidden">
            <CryptoFormModel
              closeFormCallback={() => setToogleCryptoForm(false)}
            />
          </div>
          )
        }
      </div>
    </>
  );
};

export default UpgradePlans;
