import React, { useState } from "react";
import { IoMdPerson } from "react-icons/io";
import { FaMoneyBill } from "react-icons/fa";
import WithdrawHistoryTable from "./WithdrawHistoryTable";
import DepositForm from "./DepositForm";
import axios from "axios";
import WithdrawalCryptoFormModel from "./CryptoFormModel";
import { useDispatch, useSelector } from "react-redux";
import { storeInterface } from "../../store/store";
import { FetchUserProfile } from "../../api/services";
import { setUserBalance } from "../../store/reducers/UserReducer";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface TransactionDataInterfacce {
  _id: string;
  userID: string;
  status: "pending" | "Inprogress" | "complete";
  withdrawID: string;
  type: string;
  amount: number;
  withdrawalDate: string;
  paymentMethod: string;
  transactionConfirmed: boolean;
}

function WithdrawIndex() {
  const user = useSelector((store: storeInterface) => store.userAuth);
  const [togglePaymentForm, setTooglePaymentForm] = useState(false);
  const [toggleCryptoForm, setToogleCryptoForm] = useState(false);
  const [Amount, setAmount] = useState("");
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [snackText, setSnackText] = React.useState("");
  const [type, setType] = React.useState<AlertColor>("error");
  const [currentRow, setCurrentRow] =
    React.useState<TransactionDataInterfacce>();

  const AxiosCreateTransaction = async () => {
    const body = {
      amount: parseFloat(Amount),
      withdrawalDate: new Date().toLocaleDateString(),
    };

    try {
      if (user.userBalance >= Number(Amount)) {
        const response = await axios.post("/api/v1/withdrawal", body);
        const responseUser = await FetchUserProfile();
        dispatch(setUserBalance(responseUser.totalBalance));
        console.log(response.data);
        setRefresh(!refresh);
        setOpen(true);
        setType("success");
        setSnackText(
          "Your withdrawal transaction has been intiated it will be done within 7 working days"
        );
      } else {
        setOpen(true);
        setSnackText("Please Enter Amount Less than or equal to Balance");
        setType("error");
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setSnackText("Something went wrong please try again later");
    }
    setAmount("");
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {snackText}
        </Alert>
      </Snackbar>
      <div className="w-[100%] h-[100%] relative px-5 py-5 flex flex-col items-center overflow-y-scroll scrollbar-hide transition-w duration-300 ease-in-out">
        {/* withdrawal input form */}
        <div className="w-full">
          <p className="text-lg font-semibold mb-5">Withdrawal</p>
          <div className="w-full flex flex-col items-start">
            <div className="w-[80%] sm:w-[60%] lg:w-[36%] flex flex-row items-center rounded-lg border bg-gray-200 text-gray-900 flex-1 min-w-0 text-sm pl-4 my-4">
              <IoMdPerson size={28} className="text-[#6FA7E7]" />
              <input
                type="text"
                id="website-admin"
                disabled
                className=" bg-inherit border-none outline-none focus-within:outline-none focus:outline-none focus:border-none active:outline-none active:border-none ml-2"
                placeholder={user.UserAccountID || ""}
              />
            </div>
            <div className="w-[80%] sm:w-[60%]  lg:w-[36%] flex flex-row items-center rounded-lg border text-gray-900 flex-1 min-w-0 text-sm pl-4 my-4">
              <FaMoneyBill size={28} className="text-[#6FA7E7]" />
              <input
                type="text"
                value={Amount}
                onChange={(e) => setAmount(e.target.value)}
                id="website-admin"
                className=" bg-inherit border-none outline-none focus-within:outline-none focus:outline-none focus:border-none active:outline-none active:border-none ml-2"
                placeholder="Amount"
              />
            </div>
            <button
              onClick={AxiosCreateTransaction}
              className="bg-[#5B9E72] text-white font-semibold text-lg capitalize w-[80%]  lg:w-[36%] sm:w-[60%] py-1.5 rounded-lg"
            >
              create withdrawal transaction
            </button>
          </div>
        </div>
        {/* Table depositor */}
        <div className="w-full">
          <p className="font-semibold text-lg my-3">My Withdrawal History</p>
          <WithdrawHistoryTable
            refreshtable={refresh}
            toggleCardpayment={() => setTooglePaymentForm(true)}
            toggleCryptoPayments={() => setToogleCryptoForm(true)}
            setCurrentRow={setCurrentRow}
            currentRow={currentRow}
            setOpen={setOpen}
            setType={setType}
            setSnackText={setSnackText}
            refreshTableCallback={() => setRefresh(!refresh)}
          />
        </div>
        {togglePaymentForm && (
          <div className="absolute flex flex-col items-center justify-center z-20 backdrop-blur-sm w-full h-[95%] overflow-hidden">
            <DepositForm
              CancelFormCallback={() => {
                setTooglePaymentForm(false);
              }}
            />
          </div>
        )}
        {toggleCryptoForm && (
          <div className="absolute flex flex-col min-h-full items-center justify-center z-10 backdrop-blur-sm w-full overflow-hidden">
            <WithdrawalCryptoFormModel
              closeFormCallback={() => {
                setToogleCryptoForm(false);
                setCurrentRow(undefined);
              }}
              refreshTableCallback={() => setRefresh(!refresh)}
              amount={currentRow?.amount || 0}
              id={currentRow?.["_id"] ?? ""}
              setOpen={setOpen}
              setType={setType}
              setSnackText={setSnackText}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default WithdrawIndex;
