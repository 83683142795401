import React, { useState } from "react";
import axios from "axios";
import { IoCaretBackCircleSharp } from "react-icons/io5";

interface CryptoFormModelProps {
  closeFormCallback: () => void;
  refreshTableCallback?: () => void;
  amount?: number;
  setOpen: any;
  setType: any;
  setSnackText: any;
  id: string;
}

const WithdrawalCryptoFormModel: React.FC<CryptoFormModelProps> = ({
  closeFormCallback,
  refreshTableCallback,
  amount,
  setOpen,
  setType,
  setSnackText,
  id,
}) => {
  const [address, setAddress] = useState<string>("");

  const AxiosPaidTransaction = async () => {
    if (address) {
      const body = {
        WithdrawalID: id,
        paymentMethod: "Crypto",
        withdrawalAddress: {
          wallet: address,
        },
      };
      try {
        const response = await axios.post("/api/v1/withdrawal/confirm", body);
        setType("success");
        refreshTableCallback && refreshTableCallback();
        setSnackText(response.data.message);
        setOpen(true);
        setAddress("");
        closeFormCallback();
      } catch (error: any) {
        setOpen(true);
        setType("error");
        setSnackText("Something went wrong");
      }
    } else {
      setOpen(true);
      setType("error");
      setSnackText("Please Enter Address");
    }
  };

  return (
    <div className="absolute flex flex-col items-center justify-center z-20 backdrop-blur-sm w-full h-[95%] overflow-hidden">
      <div className="relative xl:w-[56%] lg:w-[66%] md:w-[80%] w-[90%] h-auto md:h-[70%]  bg-white flex flex-col items-center justify-evenly shadow-lg border-2 border-slate-200">
        <div className="w-full flex md:flex-row items-center justify-between p-4">
          <IoCaretBackCircleSharp size={24} className="text-[#C2BAFF]" />
          <div className="flex flex-col items-start md:flex-row md:items-center">
            <p className="uppercase font-semibold text-sm mr-4">Amount</p>
            <div className="flex flex-row items-center relative my-1 md:my-0 w-[50%]">
              <p className="font-semibold text-lg absolute mx-2">$</p>
              <input
                type="number"
                disabled
                className="bg-gray-50 border ps-6 font-semibold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                placeholder=""
                // onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[2px] bg-gray-200"></div>
        <div className="w-full sm:px-10">
          <p className="uppercase font-semibold text-sm my-3 mt-2 pl-3">
            Enter Your Withdrawal Address
          </p>
          <div className="flex flex-col md:flex-row w-full my-3">
            <div className="relative w-[50%] mx-2 my-3 md:my-0">
              <input
                type="text"
                id="floating_outlined"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Wallet Address
              </label>
            </div>
          </div>
          {/* other card details */}
        </div>

        <div className="w-full mt-8 flex flex-col-reverse sm:flex-row items-center sm:items-end justify-end my-2 ">
          <button
            className="bg-[#D9D9D9] w-[200] md:w-max uppercase font-semibold px-5 rounded py-1 mx-3 my-3"
            onClick={closeFormCallback}
          >
            Cancel
          </button>
          <button
            className="bg-[#C2BAFF] w-[200] md:w-max uppercase font-semibold px-5 rounded py-1 mx-3 my-3"
            onClick={AxiosPaidTransaction}
          >
            complete payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalCryptoFormModel;
