import React, { useState } from "react";
import logo from "../assets/Group.png";
import AppBar from "../components/AppBar";
import { Outlet } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import person from "../assets/person.jpg";
import { useSelector } from "react-redux";
import { storeInterface } from "../store/store";

function Dashboard() {
  const [toggleSidebar, SetToggleSideBar] = useState(false);
  const user = useSelector((state:storeInterface)=> state.userAuth);
  return (
    <div className='className="w-[100vw] h-[100vh] overflow-y-hidden'>
      {/* Logo Header */}
      <div className="w-[100%] text-white h-[8vh] bg-[#1E3958] flex flex-row items-center justify-between px-5">
        <div className="flex flex-row text-white font-bold">
          <img src={logo} width={24} alt="" />
          <p className="uppercase ml-4">crypto</p>
        </div>
        <div className="h-[24%] hidden lg:visible w-full lg:flex flex-row items-center justify-between px-3 sm:px-8 ">
          <div className="flex flex-row items-center ml-28">
            <IoWallet size={32} className="text-[#96FFA7]" />
            <div className="ml-4">
              <p className="text-white">Total Balance</p>
              <p className="text-[#96FFA7]">{user.userBalance} USD</p>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <FaBell className="text-white sm:mx-10" />
            <div className="flex flex-row items-center">
              <img
                src={person}
                alt=""
                width={36}
                className="rounded-[50%] mx-2 sm:mx-4"
                height={18}
              />
              <div>
                <p className="text-white">Welcome back</p>
                <p className="text-[#96FFA7]">{user.UserName}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden transform-gpu transition-transform duration-500">
          {!toggleSidebar ? (
            <IoMenu onClick={() => SetToggleSideBar(true)} size={24} />
          ) : (
            <IoClose size={24} onClick={() => SetToggleSideBar(false)} />
          )}
        </div>
      </div>
      <div className="flex flex-row items-center h-[100%] w-full">
        <AppBar
          toggleState={toggleSidebar}
          closeMenuCallback={() => SetToggleSideBar(false)}
        />
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
