import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { IoWallet } from "react-icons/io5";
import { FaCoins } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { IoPerson } from "react-icons/io5";
import { useSelector } from "react-redux";
import { storeInterface } from "../store/store";
import { GiUpgrade } from "react-icons/gi";
interface SideBarMenuItemProps {
  name: string;
  renderIcon?: () => any;
  navLink: string;
  onClickCallback: () => void;
}
interface SideBarMenuItemBalanceProps {
  userName: string;
  name: string;
  renderIcon?: () => any;
}

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  name,
  renderIcon,
  navLink,
  onClickCallback,
}) => {
  return (
    <Link to={navLink} onClick={onClickCallback}>
      <div className="w-full py-4 my-3 cursor-pointer hover:bg-blue-50">
        <div className="w-full flex flex-row items-center">
          {renderIcon && renderIcon()}
          <p className="font-lg">{name}</p>
        </div>
      </div>
    </Link>
  );
};
const SideBarMenuItemBalance: React.FC<SideBarMenuItemBalanceProps> = ({
  userName,
  name,
  renderIcon,
}) => {
  return (
    <div className="w-full cursor-pointer">
      <div className="w-full flex flex-col items-center">
        {renderIcon && renderIcon()}
        <p>Hi, {userName}</p>
        <p className="font-lg">{name}</p>
      </div>
    </div>
  );
};
interface AppBarProps {
  toggleState: boolean;
  closeMenuCallback: () => void;
}
const AppBar: React.FC<AppBarProps> = ({ toggleState, closeMenuCallback }) => {
  const user = useSelector((store: storeInterface) => store.userAuth);
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login", { replace: true });
  };
  useEffect(() => {}, [toggleState]);
  return (
    <div
      className={`${
        toggleState
          ? "w-[70%] sm:w-[34%] md:w-[28%] lg:w-[34%]"
          : "w-0 lg:w-[26%] xl:w-[22%]"
      } transition-w duration-300 ease-in-out absolute lg:relative left-0 h-[100vh] flex flex-col items-center justify-between bg-slate-50 overflow-y-scroll scrollbar-hide`}
    >
      <aside
        id="sidebar-multi-level-sidebar"
        className="z-40 w-full h-[100%] bg-slate-50"
        aria-label="Sidebar"
      >
        <div className="w-full  px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <div className="block lg:hidden">
            <SideBarMenuItemBalance
              userName={user.UserName}
              name={`Total Balance: ${user.userBalance} USD`}
            />
          </div>
          <SideBarMenuItem
            onClickCallback={closeMenuCallback}
            name="Home"
            navLink="/"
            renderIcon={() => {
              return <IoWallet size={22} className="mx-6" />;
            }}
          />
          <SideBarMenuItem
            name="Trade Center"
            onClickCallback={closeMenuCallback}
            navLink="/trade-center"
            renderIcon={() => {
              return <FaCoins size={22} className="mx-6" />;
            }}
          />
          <SideBarMenuItem
            onClickCallback={closeMenuCallback}
            name="Deposit"
            navLink="/deposit"
            renderIcon={() => {
              return <GiReceiveMoney size={22} className="mx-6" />;
            }}
          />
          <SideBarMenuItem
            onClickCallback={closeMenuCallback}
            name="Withdrawal"
            navLink="/withdraw"
            renderIcon={() => {
              return <BiMoneyWithdraw size={22} className="mx-6" />;
            }}
          />
          <SideBarMenuItem
            onClickCallback={closeMenuCallback}
            name="Profile"
            navLink="/profile"
            renderIcon={() => {
              return <IoPerson size={22} className="mx-6" />;
            }}
          />
          <SideBarMenuItem
            onClickCallback={closeMenuCallback}
            name="Upgrade Plans"
            navLink="/upgrade"
            renderIcon={() => {
              return <GiUpgrade size={22} className="mx-6" />;
            }}
          />
          <div
            onClick={handleLogout}
            className="w-full flex flex-row items-center py-2 rounded-sm cursor-pointer"
          >
            <FiLogOut size={20} className="mx-6" />
            <p className="text-base font-semibold ">Logout</p>
          </div>
        </div>
        {/* <div>
          <div className="w-full px-5">
            <p className="uppercase font-bold my-4 text-[#17559D]">
              color swatcher
            </p>
            <div className="flex flex-row w-full items-center justify-evenly">
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
              <span className="bg-red-500 w-5 h-5 rounded-[50%]"></span>
            </div>
          </div>
        </div> */}
      </aside>
    </div>
  );
};

export default AppBar;
