import { createSlice } from "@reduxjs/toolkit";

interface UserProfileResponse {
  country?: string;
  email?: string;
  firstname?: string;
  gender?: string;
  lastname?: string;
  phonenumber?: string;
}

export interface userInterface {
  token: string;
  isLoggedIn: boolean;
  KYC: boolean;
  UserAccountID: string;
  UserName: string;
  userBalance: number;
  userDetails: UserProfileResponse;
}

function getUserDetails(): UserProfileResponse {
  const userDetailsString = sessionStorage.getItem("user-details");
  if (userDetailsString) {
    return JSON.parse(userDetailsString);
  } else {
    return {
      country: "",
      email: "",
      firstname: "",
      gender: "",
      lastname: "",
      phonenumber: "",
    };
  }
}

const initialState: userInterface = {
  token: "",
  isLoggedIn: false,
  UserAccountID: sessionStorage.getItem("userID") || "",
  UserName: sessionStorage.getItem("userName") || "",
  KYC: Boolean(sessionStorage.getItem("kyc")) || false,
  userBalance: 0,
  userDetails: getUserDetails(),
};

export const userReducerSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setKYC: (state, { payload }) => {
      state.KYC = payload;
    },
    setUserAccountID: (state, { payload }) => {
      state.UserAccountID = payload;
    },
    setUserName: (state, { payload }) => {
      state.UserName = payload;
    },
    setUserBalance: (state, { payload }) => {
      state.userBalance = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
  }, // Add a comma here
});

export const {
  setIsLoggedIn,
  setToken,
  setKYC,
  setUserAccountID,
  setUserName,
  setUserBalance,
  setUserDetails,
} = userReducerSlice.actions;

export default userReducerSlice.reducer;
