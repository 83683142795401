import React, { useEffect, useState } from "react";

import HomeKYCForm from "./HomeKYCForm";
import { useSelector } from "react-redux";
import { storeInterface } from "../../store/store";
import TradingViewWidget from "./Chart";

function HomeIndex() {
  const [isKYCDone, setIsKYCDone] = useState(false);
  const user = useSelector((store: storeInterface) => store.userAuth);
  useEffect(() => {
    setIsKYCDone(user.KYC);
  }, [user.KYC]);
  return (
    <>
      {/* <div className="w-[100%] h-[100%] relative flex flex-col items-center overflow-y-scroll scrollbar-hide transition-w duration-300 ease-in-out"> */}
      <div className="w-[100%] h-[100%] mb-16 py-10">
        <TradingViewWidget />
      </div>
      {!isKYCDone && (
        <div className="absolute flex flex-col items-center justify-center z-10 backdrop-blur-sm w-full h-[95%] overflow-hidden">
          <HomeKYCForm CallbackSetKYC={() => setIsKYCDone(true)} setIsKYCDone={setIsKYCDone} />
        </div>
      )}
      {/* </div> */}
    </>
  );
}

export default HomeIndex;
