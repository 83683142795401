import { configureStore } from '@reduxjs/toolkit'
import { userInterface, userReducerSlice } from './reducers/UserReducer'
import { Middleware } from 'redux'

export interface storeInterface {
  userAuth: userInterface
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loggerMiddleware: Middleware<{}> = (store) => (next) => (action) => {
  // Log the state before the action is dispatched
  console.log('State before dispatch:', store.getState())

  // Call the next middleware or the dispatch function
  const result = next(action)

  // Log the state after the action is dispatched
  console.log('State after dispatch:', store.getState())

  return result
}
const store = configureStore({
  reducer: {
    userAuth: userReducerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    // Use getDefaultMiddleware to apply default Redux Toolkit middleware
    // and then add your custom middleware after the default ones
    return getDefaultMiddleware().concat(loggerMiddleware)
  },
})

export default store
