import axios from "axios";

export const AxiosSignUpUser = async (data: object) => {
  try {
    const response = await axios.post("/api/v1/user/signup", data);
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
  }
};

export const FetchUserProfile = async () => {
  try {
    const response = await axios.get("/api/v1/user/profile");
    return response.data.data[0];
  } catch (error: any) {
    console.log(error.response);
  }
};

export const withdrawReq = async (amount: string, address: string) => {
  const body = {
    amount,
    address,
  };
  try {
    const response = await axios.post("/api/v1/user/withdraw", body);
    return response;
  } catch (error: any) {
    console.log(error.response);
  }
};
