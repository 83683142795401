import axios from "axios";
import React, { useState } from "react";
import { LuUpload } from "react-icons/lu";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface KYCFormProps {
  CallbackSetKYC: () => void;
  setIsKYCDone: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomeKYCForm: React.FC<KYCFormProps> = ({
  CallbackSetKYC,
  setIsKYCDone,
}) => {
  const [file, setfile] = useState<HTMLInputElement>();
  const [open, setOpen] = React.useState(false);
  const handleSubmition = async () => {
    try {
      if (file) {
        const response = await axios.post("/api/v1/user/kyc-verified", {});
        console.log(response.data);
        CallbackSetKYC();
      } else {
        handleClick();
      }
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="relative w-[90%] sm:w-[90%] md:w-[60%] md:h-[80%] bg-white flex flex-col items-center justify-evenly">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Please upload KYC document!
        </Alert>
      </Snackbar>
      <p className=" text-red-500 font-semibold my-3">KYC Form</p>
      <div className="w-full flex flex-col items-center sm:items-start px-4 md:px-10 mt-8">
        <p>Upload E-Mail consent shared by the Investor</p>
        <div className="w-[48%] my-3">
          <label className="flex flex-row items-center border-2 w-max px-6 rounded-2xl py-1.5 border-red-500 mb-2 text-sm font-medium text-gray-900 dark:text-white">
            <LuUpload className="text-lg mr-2" /> Upload file
            <input
              className="w-full hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 "
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              onChange={(e) => {
                setfile(e.target);
              }}
            />
          </label>
        </div>
      </div>
      <div className="w-full px-4 md:px-5">
        <p className=" text-red-500 font-semibold my-3">Note :</p>
        <ul className=" list-disc pl-12 text-sm">
          <li>Upload passport as a PDF.</li>
          <li>Keep the file size below [specified size, e.g., 5 MB].</li>
          <li>
            Ensure full visibility of passport details, including photo and
            text.
          </li>
          <li>Confirm correct orientation with no rotation.</li>
        </ul>
      </div>
      <div className="w-[80%] px-4 flex flex-row items-center my-3 text-sm">
        <button
          onClick={handleSubmition}
          className="border-red-500 px-5 py-0.5 rounded-2xl mx-5 border-2"
        >
          Submit
        </button>
        <button
          className="border-red-500 px-5 py-0.5 rounded-2xl mx-5 border-2"
          onClick={() => setIsKYCDone(true)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default HomeKYCForm;
